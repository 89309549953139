import axios from "axios";
const baseURL = process.env.VUE_APP_BASE_URL_API;

const state = {
    aboutLabel:["Karlus Morales", "Karlus Morales"],
    helloLabel:["Hola","Hello"],
    presentationLabel:["Soy Karlus Morales", "I'm Karlus Morales"],
    artistLabel:["Soy un artista", "I'm an artist"],
    p1Label:[
        "Por azares del destino nací en Guelph, una ciudad de Ontario, Canadá. Mis primeros recuerdos están llenos de naturaleza; insectos, plantas y animales hacen parte de las imágenes iniciales que marcaron mis ideas sobre el mundo y, sobre todo, mi curiosidad.  De mi padre, entomólogo, heredé el amor por los insectos; de mi madre, ingeniera agrónoma, el amor por lo natural.",
        "By pure chance I was born in Guelph, a little town in Ontario, Canada. My first memories are filled with nature, insects, plants and animals are part of the main images that formed my ideas about the world and my curiosity. From my father, an entomologist, I inherited the love for insects; from my mother, an agronomist, the love for nature."
        ],
    p2Label:[
        "Al querer saber más de lo que me rodeaba, me encontré siempre en medio del verdor descubriendo la vida de aves, flores e insectos y fue en medio de ese ambiente donde aprendí a desarrollar una visión más aguda y detallada, enfocada en la creación de una perspectiva diferente desde la valoración de lo que para otros no es evidente con una simple mirada.",
        "Wanting to learn more from my surroundings, I always found myself in the middle of the foliage discovering the life of birds, flowers and insects and it was in the centre of that environment where I developed a sharper and more detailed vision, focused on creating a different perspective from the appreciation of what is not evident with a simple glance."
    ],
    p3Label:[
        "En el año 1998 tuve mi primera cámara de rollo, una Kodak 110 que manejé de manera empírica y artesanal. A los 16 años mi padre me enseñó sobre el manejo técnico de cámaras con mayor nombre. En el 2005 llegó mi primera cámara digital y a finales del 2007 mi primera cámara digital avanzada, ésta viajó conmigo a Alemania, país donde alimenté aún más mi amor por la naturaleza y los paisajes blancos de nieve, entornos que aún hoy sigo persiguiendo.",
        "In 1998 I had my first film camera, a Kodak 110 that I used empirically. At the age of 16 my father taught me about the technicalities of film photography. In 2005 I acquired my first digital camera and at the end of 2007 my first advanced digital camera which traveled with me to  Germany, a country where I further nurtured my love for nature and snow-white landscapes, scenes that I still pursue today."
    ],
    p4Label:[
        "Después de estudiar ingeniería mecatrónica en la Escuela de Ingeniería de Antioquia en Medellín, volví a Canadá donde la fuerza del paisaje estacionario regresó junto a los encuentros con el medio ambiente que se convirtió en un elemento vital para ser el fotógrafo apasionado que soy hoy.",
        "After obtaining my bachelor’s degree in mechatronics engineering at the “Engineering College of Antioquia“ in Medellín, I returned to Canada where the might of the seasons, the astonishing landscapes and the encounters with mother nature forged the passionate photographer that I am today."
    ],
    p5Label:[
        "Regresé a Medellín para romper el paradigma de dedicarme a mi profesión y decidí formarme exclusivamente como artista. Me abrí aún más al estudio del arte de la fotografía, ahondé en su técnica y exploré la historia del arte, la filosofía y la psicología, bajo el amparo y la curaduría del maestro J. Castillo.",
        "I returned to Medellín to break the pattern and dedicate my time to my vocation and decided to educate myself as an artist. I dug deep in the study of the art of photography, its technique, and explored the history of art, philosophy and psychology, under the knowledge and curatorship of master J. Castillo."
    ],
    p6Label:[
        "Hoy me considero un creador de fotografías, retratista de la naturaleza, amante de la imagen pura que nos dan las especies singulares de la tierra. Con una especial fascinación por los paisajes, por los insectos y los animales que contemplamos impávidos por su belleza y minuciosidad, por los pequeños seres que puedo engrandecer bajo mi lente.",
        "Today I consider myself a portraitist of nature, creator of photographs, lover of the natural images that give us the unique species of the earth. With a special fascination for landscapes, insects and animals, undaunted to contemplate their beauty and detail, charmed by the small beings that I can magnify under my lens."
    ],
    p7Label:[
        "Disfruto de la aventura que viene antes del disparo y el aprendizaje que nos ofrece su resultado. Hay un mundo muy vasto allá afuera que a veces damos por sentado, y que me encantaría que todos pudiéramos descubrir.",
        "I enjoy the adventure that comes before shooting and the learnings that the result offers us. There is a very vast world out there that we sometimes take for granted, and that I would love for all of us to discover."
    ],
    inspirationLabel:["Mi inspiración","My inspiration"],
    eyeHeaderLabel:["Entorno","Environment"],
    cameraHeaderLabel:["Curiosidad","Inquisitiveness"],
    birdHeaderLabel:["Detalles","Detail"],
    eyeTextLabel:["La Madre Naturaleza me proporciona el lienzo","Mother Nature provides me with the canvas"],
    cameraTextLabel:["Una pizca de curiosidad lleva a el momento preciso","A pinch of curiosity leads to the right moment"],
    birdTextLabel:["La mezcla final son hermosas imágenes detalladas","The final concoction is beautiful detailed pictures"],
    myWorkLabel:["Mi trabajo","My work"],
    
    galeryPage:"01",
    showingPages:["02","03","04"],
    galery1:["Adoquines, Ciudades y Pueblos","Bricks, Cities and Towns"],
    galery2:["De todo un poco","And everything in-between"],
    galery3:["Bichos espeluznantes","Creepy Crawlies"],

    image1:"",
    image2:"",
    image3:"",

    collections:[],
    pageSize:3,
    currentPage:0,
    collectionsCount:0,
    lastPage:0,
    dataOk:false,
};

const getters = {};

const actions = {
    setMutation({commit},{property:prop, with:data}){
        commit('mutate', {property:prop, with:data})
    },
    getCollections({commit}, currentPage){
        commit('getCollections',currentPage)
    },
    backCollections({ commit, dispatch, state }){
        commit('backCollections')
        dispatch('getCollections',state.currentPage)
    },
    forwardCollections({ commit, dispatch, state }){
        commit('forwardCollections')
        dispatch('getCollections',state.currentPage)
    },
};

const mutations = {
    mutate(state, payload) {
        state[payload.property] = payload.with;
    },
    getCollections(state, currentPage){
        var pageSize = state.isSmallScreen =='Mobile'?1:state.isSmallScreen =='Tablet'? 2:state.pageSize;
        axios.get(`${baseURL}Collections?PageSize=${pageSize}&CurrentPage=${currentPage}`)
            .then(res => {
                if(res.status== 200){
                    if(res.data.collections.length == 3){
                        state.galery1 =  res.data.collections[0].name.split("|")
                        state.galery2 =  res.data.collections[1].name.split("|")
                        state.galery3 =  res.data.collections[2].name.split("|")
                        state.image1 = { base64:res.data.images[0].base64, alt:res.data.images[0].alt}
                        state.image2 = { base64:res.data.images[1].base64, alt:res.data.images[1].alt}
                        state.image3 = { base64:res.data.images[2].base64, alt:res.data.images[2].alt}
                    }
                    else if(res.data.collections.length == 2){
                        state.galery1 =  res.data.collections[0].name.split("|")
                        state.galery2 =  undefined
                        state.galery3 =  res.data.collections[1].name.split("|")
                        state.image1 = { base64:res.data.images[0].base64, alt:res.data.images[0].alt}
                        state.image2 = undefined
                        state.image3 = { base64:res.data.images[1].base64, alt:res.data.images[1].alt}
                    }
                    else if(res.data.collections.length == 1){
                        state.galery1 =  undefined
                        state.galery2 =  res.data.collections[0].name.split("|")
                        state.galery3 =  undefined
                        state.image1 = undefined
                        state.image2 = { base64:res.data.images[0].base64, alt:res.data.images[0].alt}
                        state.image3 = undefined
                    }
                    state.collections = res.data.collections;
                    state.collectionsCount = res.data.collectionsCount;
                    var lastPage = Math.floor(res.data.collectionsCount/pageSize)
                    lastPage = state.isSmallScreen =='Mobile' ? lastPage-1:lastPage
                    state.lastPage = lastPage;
                    var showingPages = []
                    var galeryPage = ""
                    for(var i = 0; i <= lastPage; i++){
                        if(state.currentPage != i)
                            showingPages.push((i+1).toLocaleString('en-US', {minimumIntegerDigits:2}))
                        else
                            galeryPage = (i+1).toLocaleString('en-US', {minimumIntegerDigits:2})
                    }
                    state.galeryPage = galeryPage;
                    state.showingPages = showingPages
                    state.dataOk = true;
                }
            })
            .catch(error => { 
                console.log(error);
            });
    },
    backCollections(state){
        var currentPage = state.currentPage - 1
        if(currentPage < 0){
            currentPage = state.lastPage
        }
        state.currentPage = currentPage;
    },
    forwardCollections(state){
        var currentPage = state.currentPage + 1
        if(currentPage > state.lastPage){
            currentPage = 0
        }
        state.currentPage = currentPage;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};